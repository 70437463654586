import { io } from 'socket.io-client'

const host = process.env.NODE_ENV === 'production' ? 'ws://45.179.90.87:3000' : 'ws://localhost:3000'

export function socketIO(userId) {
  return io(host, {
    transports: ['websocket'],
    query: {
      userId: Number(userId),
    },
  })
}
