<template>
  <div>
    <p class="text-2xl">
      Inserir Saldo
    </p>

    <v-divider class="mb-5" />

    <p class="text-md">
      Informe o valor para prosseguir
    </p>

    <v-card v-if="step === 1">
      <v-form autocomplete="off">
        <validation-observer ref="hirePlan">
          <v-card-text class="pa-3">
            <v-row class="mb-n6">
              <v-col
                cols="12"
                md="6"
                lg="3"
                sm="12"
                class="mb-6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Valor"
                  rules="required|validate_value:10"
                  vid="value"
                >
                  <v-text-field
                    v-model="value"
                    v-money="{ precision: 2 }"
                    :error-messages="errors"
                    :hide-details="!errors.length"
                    outlined
                    dense
                    prefix="R$"
                    label="Valor (R$)"
                    @keydown.enter.prevent="fetchOrder"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  color="transparent"
                  class="pl-4"
                  @click="openAbout"
                >
                  <v-icon
                    right
                    dark
                    size="30"
                  >
                    {{ icons.mdiHelpCircleOutline }}
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  class="pl-4"
                  @click="fetchOrder"
                >
                  Continuar
                  <v-icon
                    right
                    dark
                  >
                    {{ icons.mdiFileCheck }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />

          <carousel
            :per-page-custom="[
              [2170, 5],
              [1800, 4],
              [1400, 3],
              [800, 2],
              [700, 1],
            ]"
            :per-page="1"
            navigation-enabled
          >
            <slide
              v-for="(item, index) in levels"
              :key="index"
            >
              <pricing-card
                :item="item"
                :current-value="value"
                :is-current="user.customerLevelId === item.id"
                :is-custom="item.custom"
                @selectPlan="handleSelectPlan"
              />
            </slide>
          </carousel>

          <v-divider />
          <v-card-actions class="pa-3 justify-end">
          </v-card-actions>
        </validation-observer>
      </v-form>
    </v-card>

    <v-card v-if="step === 2">
      <div class="pa-10 text-center">
        <h1>Seu QR code foi criado com sucesso!</h1>

        <h3>Valor da compra:</h3>
        <p class="pix-value">
          R$ {{ $money(value) }}
        </p>

        <div class="pix-img-content">
          <v-img
            class="pix-img"
            :src="qrcode"
          />
        </div>
        <h3 class="mt-6 mb-3">
          Chave vinculada
        </h3>
        <p>{{ qrcodeText }}</p>
        <input
          id="pix-key"
          :value="qrcodeText"
        />
        <v-btn
          color="info"
          class="pl-4"
          @click="copyToClipboard"
        >
          {{ copyLabel }}
          <v-icon
            right
            dark
          >
            {{ icons.mdiContentCopy }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider />
      <v-card-actions class="pa-3 justify-end">
        <v-btn
          color="info"
          class="pl-4 mb-4 mt-3 ml-6"
          @click="step = 1"
        >
          <v-icon
            left
            dark
          >
            {{ icons.mdiArrowLeft }}
          </v-icon>
          Voltar
        </v-btn>
      </v-card-actions>
    </v-card>
    <dialog-form
      hideactions
      :status="dialogAbout"
      :title="'Entenda Nossos Planos e Tarifas'"
      :width="700"
      hide-save-btn
      hide-remove-btn
      @close="dialogAbout = !dialogAbout"
    >
      <div class="container">
        <ul>
          <li>Plano Starter (R$ 10,00)</li>
          <li>Plano Advanced (R$ 150,00)</li>
          <li>Plano Elite (R$ 300,00)</li>
        </ul>
        <p class="note">
          O valor de cada recarga única determinará o plano vigente ao qual você será qualificado. Uma vez qualificado
          para um plano, você manterá os benefícios deste, mesmo se as recargas subsequentes forem de valores
          inferiores.<br />Observação: As tarifas estão sujeitas a alterações, sem aviso prévio.
        </p>
      </div>
    </dialog-form>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

// eslint-disable-next-line
import { mdiFileCheck, mdiContentCopy, mdiArrowLeft, mdiHelpCircleOutline } from '@mdi/js'
import { mapActions, mapState } from 'vuex'
import { socketIO } from '@/utils/socket'

import PricingCard from '@/components/pricing-card/pricing-card.vue'

export default {
  components: { PricingCard },
  setup() {
    const services = ref([])
    const levels = ref([])
    const taxes = ref([])
    const value = ref(null)
    const step = ref(1)
    const qrcode = ref(null)
    const qrcodeText = ref(null)
    const copyLabel = ref('Copiar Chave')

    const dialogAbout = ref(false)

    return {
      services,
      levels,
      taxes,
      value,
      step,
      qrcode,
      qrcodeText,
      copyLabel,
      dialogAbout,
      icons: {
        mdiFileCheck,
        mdiContentCopy,
        mdiArrowLeft,
        mdiHelpCircleOutline,
      },
    }
  },

  computed: {
    ...mapState('user', {
      user: 'row',
    }),
    totalPayable() {
      return this.shoppingCart.reduce((acc, item) => acc + item.total, 0)
    },
  },

  watch: {
    value() {
      this.checkActive()
    },
  },

  async created() {
    await this.findUserById(localStorage.getItem('id'))
    await this.fetchTaxes()
    this.checkActive()

    const userId = localStorage.getItem('id')
    const socket = socketIO(userId)

    socket.on('payment.received', () => {
      this.$router.push('/')
    })
  },

  methods: {
    ...mapActions('services', {
      findServices: 'find',
    }),
    ...mapActions('customerLevels', {
      findLevels: 'find',
    }),
    ...mapActions('taxes', {
      findTaxes: 'find',
    }),
    ...mapActions('order', {
      createOrder: 'create',
    }),
    ...mapActions('user', {
      findUserById: 'findById',
    }),

    async fetchTaxes() {
      const levels = await this.findLevels()
      this.taxes = await this.findTaxes()
      this.services = await this.findServices()

      levels
        .filter(i => i.id >= this.user.customerLevelId)
        .map(level => {
          const taxArray = this.taxes
            .filter(i => i.customerLevelId === level.id)
            .sort((a, b) => a.serviceId - b.serviceId)

          taxArray.map(item => {
            const service = this.services.find(i => i.id === item.serviceId)
            // eslint-disable-next-line no-param-reassign
            item.service = service

            return item
          })
          // eslint-disable-next-line no-param-reassign
          level.tax = taxArray
          this.levels.push({ ...level, active: false, custom: false })

          return level
        })

      this.levels.push({
        id: -1,
        minValue: 100 * 99999,
        name: 'Personalizado',
        tax: [],
        active: false,
        custom: true,
      })
    },
    async openAbout() {
      this.dialogAbout = true
    },
    async fetchOrder() {
      if (await this.$refs.hirePlan.validate()) {
        const { data, status } = await this.createOrder({ value: this.$strip(this.value) })
        if (status === 201) {
          const { qrcode, qrcodeText } = data
          this.step = 2
          this.qrcode = qrcode
          this.qrcodeText = qrcodeText
        }
      }
    },

    handleSelectPlan(id) {
      this.levels.map(i => {
        // eslint-disable-next-line no-param-reassign
        if (i.id !== id) i.active = false

        return i
      })
    },

    copyToClipboard() {
      const copyText = document.getElementById('pix-key')
      copyText.select()
      copyText.setSelectionRange(0, 99999)
      navigator.clipboard.writeText(copyText.value)
      this.copyLabel = 'Chave copiada!'
      setTimeout(() => {
        this.copyLabel = 'Copiar Chave'
      }, 5000)
    },

    checkActive() {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.levels.length; i++) {
        this.levels[i].active = false
      }

      const value = this.$strip(this.value)

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.levels.length; i++) {
        if (i === this.levels.length - 1) {
          this.levels[this.levels.length - 1].active = true
          break
        }
        if (
          //
          // eslint-disable-next-line
          (i === 0 && Number(value) < this.levels[i + 1].minValue) ||
          (Number(value) >= this.levels[i].minValue && Number(value) < this.levels[i + 1].minValue)
        ) {
          this.levels[i].active = true
          break
        }
      }
    },
  },
}
</script>
<style lang="scss">
.pix-value {
  font-size: 40px;
}

.pix-img-content {
  display: flex;
  justify-content: center;
  align-items: center;

  .pix-img {
    max-width: 220px;
  }
}

#pix-key {
  display: none;
}

.note {
  margin-top: 20px;
  font-style: italic;
  text-align: center;
  color: #777;
}
</style>
